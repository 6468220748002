import Vue from 'vue'
import Vuex from 'vuex'
// import router from './../router'

import createPersistedState from "vuex-persistedstate";

import axios from 'axios'
import crypto  from 'crypto'
import router from '../router'

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

Vue.use(Vuex)

const UserStore = new Vuex.Store({

	plugins: [createPersistedState({
		paths: 'data',
		key: 'auth',
		storage: window.sessionStorage
	})],

	state: {
		packageVersion: "2.1.32",
		currentSite: "",
		count: 0,
		progressPercent: 0,
		progressType: 'is-success',
		progressMax: '100',
		progressSize: 'is-small',
		logoutTimeLeft: 300,
		logoutTimeoutSeconds: 300,
		user: {},
		userFields: ['id', 'name', 'email', 'activeStore', 'storeUsers', 'phoneNumber', 'activeStoreSubscriptionStatus','lastHeartBeat'],
		storeFields: ['id', 'storeName', 'email', 'timeZone', 'role', 'active', 'companyId', 'addressLine1', 'city', 'state','veederSubscription','verifoneSubscription','bankSubscription', 'storeDeviceStatus', 'lastHeartBeat'],
		payrollExpenseTypes: ["Payroll","Payroll - Manager", "Payroll - Bonus"],
		payrollExpenseTypeIds: ["1","139","140"],
		pageLoading: false,
		store: {},
		storeState: {},
		countryCodes: [
			{
				"countryName": "United States of America", 
				"countryCode": "USA",
				"shortCode": "US", 
				"currencyName": "Dollar",
				"currencyCode": "USD",
				"phoneCountryCode": "1"
			},
			{
				"countryName": "Canada", 
				"countryCode": "CAN",
				"shortCode": "CA", 
				"currencyName": "CA Dollar",
				"currencyCode": "CAD",
				"phoneCountryCode": "1"
			},
			{
				"countryName": "United Kingdom", 
				"countryCode": "GBR",
				"shortCode": "GB",
				"currencyName": "Pound",
				"currencyCode": "GBP",
				"phoneCountryCode": "44"
			},
			{
				"countryName": "India", 
				"countryCode": "IND",
				"shortCode": "IN",
				"currencyName": "Rupee",
				"currencyCode": "INR",
				"phoneCountryCode": "91"
			},
			{
				"countryName": "Mexico", 
				"countryCode": "MEX",
				"shortCode": "MX",
				"currencyName": "Nuevo Peso",
				"currencyCode": "MXN",
				"phoneCountryCode": "52"
			},
		],
		lotteryBarCodeFormats: [
			{"countryCode": "US", "stateCode": "AZ", "Game": 4, "Book": 6, "Ticket": 3},
			{"countryCode": "US", "stateCode": "CA", "Game": 4, "Book": 8, "Ticket": 4},
			{"countryCode": "US", "stateCode": "FL", "Game": 4, "Book": 7, "Ticket": 3},
			{"countryCode": "US", "stateCode": "GA", "Game": 4, "Book": 6, "Ticket": 3},
			{"countryCode": "US", "stateCode": "IL", "Game": 4, "Book": 7, "Ticket": 3},
			{"countryCode": "US", "stateCode": "IN", "Game": 4, "Book": 6, "Ticket": 3},
			{"countryCode": "US", "stateCode": "MI", "Game": 4, "Book": 6, "Ticket": 3},
			{"countryCode": "US", "stateCode": "PA", "Game": 4, "Book": 7, "Ticket": 3},
			{"countryCode": "US", "stateCode": "TN", "Game": 4, "Book": 6, "Ticket": 3},
			{"countryCode": "US", "stateCode": "TX", "Game": 4, "Book": 7, "Ticket": 3},
			{"countryCode": "US", "stateCode": "WI", "Game": 4, "Book": 7, "Ticket": 3}
		],
		stateBarCodeFormat: {},
		accessToken: null,
		refreshToken: null,
		storeRegisterPasswordExpireIn: 0,
		isEditing: false,
		lastHeartBeat: Date(),
		storeDeviceStatus: "OK",
		storeSettings: [],
		statesInCountry: [],
		userAgent:  navigator.userAgent,
		storeMappings: [
                {"name": "Beer & Wine", "value": "BeerWine"},
				{"name": "Car Wash", "value": "CarWash"},
                {"name": "Cigarette Pack", "value": "Cigarette"},
				{"name": "Cig. Carton 10pk", "value": "CigaretteCarton10"},
				{"name": "Fuel", "value": "Fuel"},
				{"name": "Gaming", "value": "Gaming"},
                {"name": "Grocery", "value": "Grocery"},
                {"name": "Inside Sales", "value": "InsideSales"},
                {"name": "Instant Lottery", "value": "InstantLottery"},
                {"name": "Lottery", "value": "Lottery"},
                {"name": "Spirit/Liquor", "value": "Spirit"},
                {"name": "Taxes", "value": "Taxes"},
                {"name": "Other", "value": "Other"},
            ],
		storeSettingsOptions1: [
			{
				"key": "SETTINGS_AUTHORIZED_EMAILS", 
				"type": "string",
				"label": "Settings Authorized Emails",
				"field": "settingsAuthorizedEmails"
			}
		],
		storeSettingsOptions: [
			{
				"key": "SETTINGS_AUTHORIZED_EMAILS", 
				"type": "string",
				"label": "Settings Authorized Emails",
				"field": "settingsAuthorizedEmails"
			},
			{
				"key": "PROFIT_MARGIN_AUTHORIZED_EMAILS",
				"type": "string",
				"label": "Profit Margin Setting Authorized Emails",
				"field": "profitMarginAuthorizedEmails"
			},	
			{
				"key": "SETTINGS_AUTHORIZED_PAYROLL", 
				"type": "string",
				"label": "Settings Authorized Payroll",
				"field": "settingsAuthorizedPayroll"
			},
			{
				"key": "CC_VENDOR_ID", 
				"type": "number",
				"label": "Credit Card Processor",
				"field": "ccVendorId"
			},
			{
				"key": "PROFIT_CALCULATION_METHOD", 
				"type": "string",
				"label": "Profit Calculation Method",
				"field": "profitCalculationMethod"
			},
			{
				"key": "SHIFT_EDIT_MANAGER_ALLOWED_DAYS", 
				"type": "number",
				"label": "Shift Edit Allowed Days - Manager",
				"field": "shiftManagerAllowDays"
			},
			{
				"key": "SHIFT_EDIT_STAFF_ALLOWED_DAYS", 
				"type": "number",
				"label": "Shift Edit Allowed Days - Staff",
				"field": "shiftStaffAllowDays"
			},
			{
				"key": "NOTIFICATION_EMAIL_LIST", 
				"type": "string",
				"label": "Notification Email Addresses",
				"field": "notificationEmailList"
			},                                                   
			{
				"key": "SHIFT_UNLOCK_EMAIL_ENABLED", 
				"type": "number",
				"label": "Email Notification",
				"field": "shiftUnlockEmailEnabled"
			},
			{
				"key": "SHIFT_UPDATE_EMAIL_ENABLED", 
				"type": "number",
				"label": "Email Notification",
				"field": "shiftUpdateEmailEnabled"
			},
			{
				"key": "SHIFT_CLOSE_EMAIL_ENABLED", 
				"type": "number",
				"label": "Email Notification",
				"field": "shiftCloseEmailEnabled"
			},
			{
				"key": "DAILY_CLOSE_EMAIL_ENABLED", 
				"type": "number",
				"label": "Email Notification",
				"field": "dailyCloseEmailEnabled"
			},
			{
				"key": "TRACK_INSTANT_LOTTERY_BOOKS",
				"type": "string",
				"label": "Trank Instant Lottery Books",
				"field": "trackInstantLotteryBooks"
			},
			{
				"key": "LOTTERY_STATE_ID", 
				"type": "number",
				"label": "Instant Lottery State",
				"field": "lotteryStateId"
			},
			{
				"key": "LOTTERY_AUDIT_OPTION_ENABLE", 
				"type": "number",
				"label": "Enable Lottery Audit",
				"field": "showLotteryAudit"
			},						
			{
				"key": "CREDIT_DEBIT_NET_DEPOSIT_REPORT",
				"type": "string",
				"label": "Enable Credit/Debit Net Deposit Report",
				"field": "showCreditDebitNetDeposit"
			},
			{
				"key": "ACCOUNTANT_SHOW_SALES",
				"type": "number",
				"label": "Include Sales Details in accountant report",
				"field": "accountantShowSales"
			},
			{
				"key": "ACCOUNTANT_SHOW_PAYMENT",
				"type": "number",
				"label": "Include Sales Details in accountant report",
				"field": "accountantShowPayment"
			},	
			{
				"key": "ACCOUNTANT_SHOW_LOTTERY",
				"type": "number",
				"label": "Include Lottery in accountant report",
				"field": "accountantShowLottery"
			},
			{
				"key": "ACCOUNTANT_SHOW_INVENTORY_PURCHASE",
				"type": "number",
				"label": "Include Inventory Purchase in accountant report",
				"field": "accountantShowInventoryPurchase"
			},
			{
				"key": "ACCOUNTANT_SHOW_FUEL_INVOICE",
				"type": "number",
				"label": "Include Fuel Invoice in accountant report",
				"field": "accountantShowFuelInvoice"
			},
			{
				"key": "ACCOUNTANT_SHOW_FUEL_DELIVERY",
				"type": "number",
				"label": "Include Fuel Delivery in accountant report",
				"field": "accountantShowFuelDelivery"
			},
			{
				"key": "COMMISSION_OPTION_ENABLE",
				"type": "string",
				"label": "Enable Commission",
				"field": "showCommission"
			},
			{
				"key": "COMMISSION_AUTHORIZED_EMAILS",
				"type": "string",
				"label": "Commission Authorized Emails",
				"field": "commissionAuthorizedEmails"
			},						
			{
				"key": "COMMISSION_STORE_PERCENTAGE",
				"type": "number",
				"label": "Store Commission (%)",
				"field": "commissionStorePercentage"
			},
			{
				"key": "COMMISSION_INCREASE_SALES_PERCENTAGE",
				"type": "number",
				"label": "Increase from Previous Year Sales Commission (%)",
				"field": "commissionSalesIncreasePercentage"
			},
			{
				"key": "COMMISSION_MANAGER_PERCENTAGE",
				"type": "number",
				"label": "Manager Commission (%)",
				"field": "commissionManagerPercentage"
			},
			{
				"key": "COMMISSION_ASSISTANT_MANAGER_PERCENTAGE",
				"type": "number",
				"label": "Assistant Manager Commission (%)",
				"field": "commissionAssistantManagerPercentage"
			},
			{
				"key": "COMMISSION_EMPLOYEE_PERCENTAGE",
				"type": "number",
				"label": "Employee Commission (%)",
				"field": "commissionEmployeePercentage"
			},
			{
				"key": "SEPARATED_MONTHEND_INVENTORY",
				"type": "string",
				"label": "Separated Month End Inventory",
				"field": "separatedMonthendInventory"
			},
			{
				"key": "SHIFT_NUMBER_TO_EMAIL_ON_UPDATE",
				"type": "number",
				"label": "Shift Number to Email on Update.",
				"field": "shiftNumberToEmailUpdate"
			},
			{
				"key": "SHIFT_UPDATE_EMAILS",
				"type": "string",
				"label": "Shift Update Notification Email Addresses.",
				"field": "shiftUpdateNotificationEmailAddresses"
			},
			{
				"key": "OWNER_OVERRIDE_PASSWORD",
				"type": "string",
				"label": "Owner override password.",
				"field": "ownerOverridePassword"
			},
			{
				"key": "LOTTERY_INSIDE_SALE_DIRECTION",
				"type": "string",
				"label": "Inside Sale Direction.",
				"field": "lotteryInsideSaleDirection"
			},
			{
				"key": "PAYROLL_WEEK_BEGIN_DAY_NUMBER",
				"type": "number",
				"label": "Payroll Week Begin Day.",
				"field": "payrollWeekBeginDayNumber"
			},
			{
				"key": "PAYROLL_BUDGETED_HOURS",
				"type": "number",
				"label": "Payroll Budgeted Hours.",
				"field": "payrollBudgetedHours"
			},
			{
				"key": "PAYROLL_NUMBER_OF_DAYS_AFTER_TO_PAY",
				"type": "number",
				"label": "Payroll Number of Days After Pay Period to Pay.",
				"field": "payrollNumDaysAfterPayPeriodToPay"
			},
			{
				"key": "PAYROLL_AUTHORIZED_EMAILS",
				"type": "string",
				"label": "Payroll Authorized Emails",
				"field": "payrollAuthorizedEmails"
			},
			{
				"key": "BANK_ACCOUNT_AUTHORIZED_EMAILS",
				"type": "string",
				"label": "Bank Account Authorized Emails",
				"field": "bankAccountAuthorizedEmails"
			},
			{
				"key": "PRICEBOOK_UPDATE_AUTHORIZED_EMAILS",
				"type": "string",
				"label": "Pricebook Update Authorized Emails",
				"field": "priceBookAuthorizedEmails"
			},
			{
				"key": "FPCALC_TAXABLE_REGULAR",
				"type": "string",
				"label": "FP Calc - Taxable Regular",
				"field": "fpcalcTaxableRegular"
			},
			{
				"key": "FPCALC_TAXABLE_PREMIUM",
				"type": "string",
				"label": "FP Calc - Taxable Premium",
				"field": "fpcalcTaxablePremium"
			},
			{
				"key": "FPCALC_TAXABLE_DIESEL",
				"type": "string",
				"label": "FP Calc - Taxable Diesel",
				"field": "fpcalcTaxableDiesel"
			},
			{
				"key": "FPCALC_BASE_PRICE_REGULAR",
				"type": "string",
				"label": "FP Calc - Base Price Regular",
				"field": "fpcalcBasePriceRegular"
			},
			{
				"key": "FPCALC_BASE_PRICE_PREMIUM",
				"type": "string",
				"label": "FP Calc - Base Price Premium",
				"field": "fpcalcBasePricePremium"
			},
			{
				"key": "FPCALC_BASE_PRICE_DIESEL",
				"type": "string",
				"label": "FP Calc - Base Price Diesel",
				"field": "fpcalcBasePriceDiesel"
			},
			{
				"key": "FPCALC_BREAK_EVEN_PRICE_REGULAR",
				"type": "string",
				"label": "FP Calc - Break-Even Cash Price",
				"field": "fpcalcBreakEvenPriceRegular"
			},
			{
				"key": "FPCALC_BREAK_EVEN_PRICE_PREMIUM",
				"type": "string",
				"label": "FP Calc - Break-Even Cash Price",
				"field": "fpcalcBreakEvenPricePremium"
			},
			{
				"key": "FPCALC_BREAK_EVEN_PRICE_DIESEL",
				"type": "string",
				"label": "FP Calc - Break-Even Cash Price",
				"field": "fpcalcBreakEvenPriceDiesel"
			},
			{
				"key": "FPCALC_REQUIRED_PROFIT",
				"type": "string",
				"label": "FP Calc - Required Profit",
				"field": "fpcalcRequiredProfit"
			},
			{
				"key": "FPCALC_FED_GASOLINE_HAZ_SSF_TAX",
				"type": "string",
				"label": "FP Calc - Federal Hazardous Substance Superfund Financing Tax",
				"field": "fpcalcFedGasolineHAZSSF"
			},
			{
				"key": "FPCALC_FED_DIESEL_HAZ_SSF_TAX",
				"type": "string",
				"label": "FP Calc - Federal Hazardous Substance Superfund Financing Tax",
				"field": "fpcalcFedDieselHAZSSF"
			},
			{
				"key": "FPCALC_FED_LUST_TAX",
				"type": "string",
				"label": "FP Calc - Federal Leaking Underground Storage Tank Tax",
				"field": "fpcalcFedLUSTTax"
			},
			{
				"key": "FPCALC_FED_OIL_SPILL_TAX",
				"type": "string",
				"label": "FP Calc - Federal Oil Spill Tax",
				"field": "fpcalcFedOilSpillTax"
			},
			{
				"key": "FPCALC_FED_GASOLINE_EXCISE_TAX",
				"type": "string",
				"label": "FP Calc - Federal Excise Tax",
				"field": "fpcalcFedGasolineExciseTax"
			},
			{
				"key": "FPCALC_FED_DIESEL_EXCISE_TAX",
				"type": "string",
				"label": "FP Calc - Federal Excise Tax",
				"field": "fpcalcFedDieselExciseTax"
			},
			{
				"key": "FPCALC_STATE_GASOLINE_USE_TAX",
				"type": "string",
				"label": "FP Calc - State Diesel Use Tax",
				"field": "fpcalcStateGasolinelUseTax"
			},			
			{
				"key": "FPCALC_STATE_DIESEL_USE_TAX",
				"type": "string",
				"label": "FP Calc - State Gasoline Use Tax",
				"field": "fpcalcStateDieselUseTax"
			},
			{
				"key": "FPCALC_STATE_UST_TAX",
				"type": "string",
				"label": "FP Calc - State Underground Storage Tank Tax",
				"field": "fpcalcStateUSTTax"
			},
			{
				"key": "FPCALC_STATE_ENV_TAX",
				"type": "string",
				"label": "FP Calc - State Environment Impact Tax",
				"field": "fpcalcStateENVTax"
			},
			{
				"key": "FPCALC_STATE_GASOLINE_TAX",
				"type": "string",
				"label": "FP Calc - State Gasoline Tax",
				"field": "fpcalcStateGasolineTax"
			},	
			{
				"key": "FPCALC_STATE_DIESEL_TAX",
				"type": "string",
				"label": "FP Calc - State Diesel Tax",
				"field": "fpcalcStateDieselTax"
			},
			{
				"key": "FPCALC_CITY_FUEL_TAX",
				"type": "string",
				"label": "FP Calc - City Fuel Tax",
				"field": "fpcalcCityFuelTax"
			},
			{
				"key": "FPCALC_County_FUEL_TAX",
				"type": "string",
				"label": "FP Calc - County Fuel Tax",
				"field": "fpcalcCountyFuelTax"
			},
			{
				"key": "FPCALC_STATE_SALES_TAX_PERC",
				"type": "string",
				"label": "FP Calc - State Sales Tax Percentage",
				"field": "fpcalcSalesTaxPerc"
			},	
			{
				"key": "FPCALC_STATE_SALES_TAX_PREPAID",
				"type": "string",
				"label": "FP Calc - State Sales Tax Prepaid",
				"field": "fpcalcSalesTaxPrepaid"
			},
			{
				"key": "FPCALC_CREDIT_CARD_PERCENTAGE",
				"type": "string",
				"label": "FP Calc - Credit Card Payment",
				"field": "fpcalcCreditCardPerc"
			},
			{
				"key": "FPCALC_FREIGHT_PER_GAL",
				"type": "string",
				"label": "FP Calc - Freight Per Gal",
				"field": "fpcalcFreightPerGal"
			},
			{
				"key": "FPCALC_REGULAR_CASH_PRICE",
				"type": "string",
				"label": "FP Calc - Regular Cash Price",
				"field": "fpcalcRegularCashPrice"
			},
			{
				"key": "FPCALC_PREMIUM_CASH_PRICE",
				"type": "string",
				"label": "FP Calc - Premium Cash Price",
				"field": "fpcalcPremiumCashPrice"
			},
			{
				"key": "FPCALC_DIESEL_CASH_PRICE",
				"type": "string",
				"label": "FP Calc - Diesel Cash Price",
				"field": "fpcalcDieselCashPrice"
			},
																							
		],
		settingsValues: {
			"settingsAuthorizedEmails": {},
			"ccVendorId": {},
			"lotteryStateId": {},
			"showLotteryAudit": {},
			"trackInstantLotteryBooks": {},
			"shiftManagerAllowDays": {},
			"shiftStaffAllowDays": {},
			"notificationEmailList": {},
			"shiftUnlockEmailEnabled": {},
			"shiftUnlockEmailAddresses": {},
			"shiftUpdateEmailEnabled": {},
			"shiftUpdateEmailAddresses": {},
			"shiftCloseEmailEnabled": {},
			"dailyCloseEmailEnabled": {},
			"showCreditDebitNetDeposit": {},
			"showCommission": {},
			"commissionAuthorizedEmails": {},
			"commissionStorePercentage": {},
			"commissionSalesIncreasePercentage": {},
			"commissionManagerPercentage": {},
			"commissionAssistantManagerPercentage": {},
			"commissionEmployeePercentage": {},
			"settingsAuthorizedPayroll": {},
			"profitCalculationMethod": {},
			"profitMarginAuthorizedEmails": {},
			"monthEndSeparateInventory": {},
			"shiftNumberToEmailUpdate": {},
			"shiftUpdateNotificationEmailAddresses": {},
			"ownerOverridePassword": {},
			"lotteryInsideSaleDirection": {},
			"payrollWeekBeginDayNumber": {},
			"payrollBudgetedHours": {},
			"payrollNumDaysAfterPayPeriodToPay": {},
			"payrollAuthorizedEmails": {},
			"bankAccountAuthorizedEmails": {},
			"priceBookAuthorizedEmails": {},
			"fpcalcTaxableRegular": {},
			"fpcalcTaxablePremium": {},
			"fpcalcTaxableDiesel": {},
			"fpcalcBasePriceRegular": {},
			"fpcalcBasePricePremium": {},
			"fpcalcBasePriceDiesel": {},
			"fpcalcBreakEvenPriceRegular": {},
			"fpcalcBreakEvenPricePremium": {},
			"fpcalcBreakEvenPriceDiesel": {},
			"fpcalcRequiredProfit": {},
			"fpcalcStateGasolinelUseTax": {},
			"fpcalcStateDieselUseTax": {},
			"fpcalcStateUSTTax": {},
			"fpcalcStateENVTax": {},
			"fpcalcStateGasolineTax": {},
			"fpcalcStateDieselTax": {},
			"fpcalcFedGasolineHAZSSF": {},
			"fpcalcFedDieselHAZSSF": {},
			"fpcalcFedLUSTTax": {},
			"fpcalcFedOilSpillTax": {},
			"fpcalcFedGasolineExciseTax": {},
			"fpcalcFedDieselExciseTax": {},
			"fpcalcCityFuelTax": {},
			"fpcalcCountyFuelTax": {},
			"fpcalcSalesTaxPerc": {},
			"fpcalcSalesTaxPrepaid": {},
			"fpcalcCreditCardPerc": {},
			"fpcalcFreightPerGal": {},
			"fpcalcRegularCashPrice": {},
			"fpcalcPremiumCashPrice": {},
			"fpcalcDieselCashPrice": {}
		},		
	},

	getters: {

		getAppVersion (state) {
			return state.packageVersion
		},

		getUserAgent (state) {
			var string1 = state.userAgent.replace(/[#_)(/;]/g,' ')
			var string2 = string1.split(/,| /) 
			var string3 = string2[0]+string2[3]+string2[5]+string2[6]+string2[7]+string2[9]+string2[15]+string2[17]+string2[19]+string2[21]+(window.screen.height+window.screen.width)+window.navigator.maxTouchPoints+window.navigator.language
			var string4 = crypto.createHash('md5').update(string3).digest("hex")
			return string4
		},

		getCurrentHost (state) {
			state.currentSite = window.location.host
			return state.currentSite
		},

		isAuth (state) {
			return state.authenticated
		},

		getProgressType(state) {

			return state.progressType

		},
		
		getProgressPercent(state) {

			return state.progressPercent

		},

		getAccessToken (state) {
			return state.accessToken
		},

		getRefreshToken (state) {
			return state.refreshToken
		},

		getUser (state) {
			return state.user
		},		

		getUserName (state) {
			return state.user.name
		},

		getUserEmail (state) {
			return state.user.email
		},

		getUserPhone (state) {
			return state.user.phoneNumber
		},


		getStore(state) {
			return state.store
		},		

		getStores (state) {
			return state.user.storeUsers
		},

		getStoreRegisterPasswordExpireIn(state) {
			return state.storeRegisterPasswordExpireIn 
		},

		getActiveStore (state, getters) {
			if (state.user.name) {
				if (state.user.storeUsers.length > 0) {

					if (getters.getStoreById(state.user.activeStore) == null) {
						return getters.getFirstStore
					} else {
						return getters.getStoreById(state.user.activeStore)
					}
				}
	
			}
			return false

		},

		getFirstStore (state) {
			
			if (state.user.activeStore) {
				return state.user.activeStore
			} else {
				if (state.user.storeUsers.length > 0) {

					return state.user.storeUsers[0].storeId
				} else {
					return false
				}
			}
		},

		getStoreById: (state) => (store_id) => {
			
			if (state.user.storeUsers.length > 0) {
				var store = state.user.storeUsers.find( storeUsers => storeUsers.storeId === store_id);

				return store

			} else {

				return false;
			}
		},

		getStoreState(state) {
			return state.storeState
		},

		getStoreState2(state) {
			return state.state.code
		},

		getInactiveStores(state) {
			return state.user.storeUsers.filter( (store) => {
				return store.storeId != state.user.activeStore
			})
		},

		getInactiveStoresSortedByName(state, getters) {
			return getters.getInactiveStores.sort((a, b) => (a.storeName > b.storeName) ? 1 : -1)
		},

		getLogoutTimeOutSeconds(state) {
			return state.logoutTimeoutSeconds
		},

		getLogoutTimeOutLeft(state) {
			return state.logoutTimeLeft
		},

		getPageLoading (state) {
			return state.pageLoading
		},

		getActiveStoreSubscriptionStatus(state) {
			return state.store.subscriptionStatus
		},

		getDeviceLastHeartBeat(state) {
			return state.store
		},
	
		getStoreDeviceStatus(state) {
			return state.storeDeviceStatus
		},		

		getIsEditing(state) {
			return state.isEditing
		},

		getStoreSettingsOptions(state) {
			return state.storeSettingsOptions
		},
		
		getStoreSettingsValues(state) {
			return state.settingsValues
		},

		getStoreSettingsValue: (state) => (option) => {
			if (state.settingsValues[option] == null) {
				return 1
			}
			return state.settingsValues[option]
		},

		getCreditCardVendor(state) {
			return state.settingsValues.ccVendorId
		},

		getTrackInstantLotteryBooks(state) {
			return state.settingsValues.trackInstantLotteryBooks
		},

		getLotteryState(state) {
			return state.settingsValues.lotteryStateId
		},

		getLotteryBarCodeFormats(state) {
			return state.lotteryBarCodeFormats
		},

		getStateBarCodeFormat(state,getters) {
			var storeState = state.store.state
			var storeCountry = state.store.country
			var barcodeFormat = state.lotteryBarCodeFormats.find(o => o.stateCode === storeState && o.countryCode === storeCountry)
			if (!barcodeFormat) {
				barcodeFormat = {"countryCode": getters.getStoreState.countryCode, "stateCode": getters.getStoreState.code, "Game": 4, "Book": 9, "Ticket": 3}
			} 
			// console.log("Getting State Code", state.store.state,state.store.state,state.store.country,barcodeFormat)
			return barcodeFormat
			// console.log("Getting State Code Not Found", state.store.state,state.store.state,state.store.country,barcodeFormat)
			// return 
			// return state.stateBarCodeFormat
		},

		getCountryCurrencyCode(state) {
			var country = state.countryCodes.find(o => o.shortCode === state.store.country )
			if (country) {
				return country.currencyCode
			} else {
				return "USD"
			}
		},

		getCountryCurrencyFormat(state,getters) {
			const format = new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: getters.getCountryCurrencyCode,
				})
			return format
		},

		getShiftManagerAllowDays(state) {
			return state.settingsValues.shiftManagerAllowDays
		},

		getShiftStaffAllowDays(state) {
			return state.settingsValues.shiftStaffAllowDays
		},

		getDeptMappings(state) {
			return state.storeMappings.map(a => a.value)
			// return state.deptMappings
		},

		getStoreMappings(state) {
			return state.storeMappings
		},

		getPayrollExpenseTypeIds(state) {
			return state.payrollExpenseTypeIds
		},

		getIsPayrollExpenseType: (state) => (id) => {
			if (id !== undefined) {
				if (state.payrollExpenseTypeIds.includes(id.toString())) {
					return true
				} 
			}			
			return false
		},
		
	},

	mutations: {

		userLogout(state) {

            axios.post('/sapi/users/logout', {
				'user': state.user.name
				},
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken
                    }, 
                }                 
            )
            .then( ( ) => {       
				state.user = {}
				state.store = {}
				state.accessToken = null
				state.refreshToken = null
				state.authenticated = false
				// this.dispatch('entities/deleteAll')
				sessionStorage.removeItem('auth')
				sessionStorage.removeItem('storeFields')
				sessionStorage.removeItem('store')
				sessionStorage.removeItem('storeState')
				Object.keys(sessionStorage)
				.forEach(function(k) {
					sessionStorage.removeItem(k);
				});				
				sessionStorage.clear()
				var n = sessionStorage.length;
				while(n--) {
				var key = sessionStorage.key(n);
					sessionStorage.removeItem(key);
				}
				// UserStore.commit('setProgressPercent', 100)
				if (window.location.pathname != "/login") {
					// window.location.href = "/login"
					router.push({ path: '/login' })
						.catch(() => true);
				}
				
				
			})
		},

		increment (state) {
			state.count++
		},
		
		setProgressType(state,type) {

			state.progressType = type

		},

		setProgressPercent(state,pct) {
			if (pct > state.progressMax) {
				state.progressPercent = 100
			} else {
				state.progressPercent = pct
			}

		},

		loadUser(state) {
			state.user = JSON.parse(sessionStorage.getItem('user'))	
		},

		setIsEditing(state, data) {
			if (data == true) {
				state.isEditing = true
			}
			if (data == false) {
				state.isEditing = false
			}
		},

		setUser(state,data) {
			var userData = {}
			state.userFields.forEach(element => {
				userData[element] = data[element]							
			});
			state.user = userData
			state.authenticated = true

			if (state.user.storeUsers) {
				if (state.user.activeStore == null) {
					if (state.user.storeUsers[0]) {
						this.dispatch("setActiveStoreDetails",state.user.activeStore)
					} else {
						state.user.role = "NEW"
					}
				}
			}
		},

		setAccessToken(state, token) {
			state.accessToken = token
		},

		setRefreshToken(state, token) {
			state.refreshToken = token
		},

		setActiveStore(state, store_id) {
			if (store_id) {
				
				state.setProgressType ='is-success'
				state.setProgressPercent = 25
				axios.post(process.env.VUE_APP_API_ROOT_URL + 'users/setActiveStore', 
					{
					id: state.user.id,
					storeId: store_id
					}, 
					{
						headers: {
							'Authorization': 'Bearer ' + state.accessToken,
							'Refresh-Token': state.refreshToken
						}, 
					})
					.then( (response) => {
						if (response.data.accessToken) state.accessToken = response.data.accessToken
						state.setProgressPercent = 70
						state.user.activeStore = response.data.id
						UserStore.commit('setProgressPercent', 80)
						state.user.activeStoreSubscriptionStatus = response.data.subscriptionStatus
						UserStore.commit('setProgressPercent', 100)
						location.reload()
						state.pageLoading = false
					})
					.catch( (err) => {
                        this.isLoading = false
						if (err.response) {
							if (err.response.status === 403) {
								alert('Session Expired. Please log in again!')
								state.commit('userLogout')
							}
						}

					})					
			}

		},

		setStoreDeviceStatus(state, status) {
			state.storeDeviceStatus = status
		},

		decrementLogoutTimeLeft(state) {
			state.logoutTimeLeft--
		},

		resetLogoutTimeLeft(state,seconds) {
			state.logoutTimeLeft = seconds
		},

		setStore(state,store_id) {


			if ( store_id ) {				

				axios.get('/sapi/stores/' + store_id, {
                    headers: {
						'Authorization': 'Bearer ' + state.accessToken,
						'Refresh-Token': state.refreshToken				},				
                    })
				.then( response => {

					state.store = response.data.data
					UserStore.commit('fetchStoreState', state.store.id)
				})
				.catch( (err) => {

					state.store = {}
					state.storeState = {}

					if (process.env.VUE_APP_DEBUG == 'true') console.log(err)
					this.isLoading = false

				})

				
			} else {
				if (process.env.VUE_APP_DEBUG == 'true') console.log("Invalid store", store_id)
			}			

		},

		sleep(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		},

		fetchStoreState(state) {

			if (state.store.country === undefined) {
				return
			}
			const url = '/sapi/' + 'global/country/' + state.store.country + "/states/" + state.store.state 

			axios.get(url,
				{
					headers: {
						'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
						'Refresh-Token': UserStore.getters.getRefreshToken
					},                   
				})
				.then( (response) => {
					if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
					if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data.data)

					state.storeState = response.data.data

				})
				.catch( (err) => {
					console.log("Failed to set Store State", err)
				})
		},


		fetchStoreSettings(state, store_id) {
			if (store_id) {
				const url = process.env.VUE_APP_API_ROOT_URL + 'stores/' + + store_id + "/settings"
				axios.get(url, {
					headers: {
						'Authorization': 'Bearer ' + state.accessToken,
						'Refresh-Token': state.refreshToken				
					},				
				})
				.then( response => {
					state.storeSettings = response.data.data
					
					state.storeSettingsOptions.forEach( (item) => {
						state.settingsValues[item.field] = state.storeSettings.find(o => o.key === item.key)
		
						if (state.settingsValues[item.field] === undefined) {
							state.settingsValues[item.field] = {
								"id": null,
								"key": item.key,
								"value": null
							}

						}
						if (item.type == "number" && state.settingsValues[item.field] !== undefined) {
							state.settingsValues[item.field].value = parseFloat(state.settingsValues[item.field].value)
						}
					})
					
				})
				.catch( err => {
					// failed(err)
					console.log("Error",err)
				})
			}
		},

        fetchStoreRegisterPasswordExpireIn(state,store_id) {
			
			if ( store_id ) {
				
				const url = process.env.VUE_APP_API_ROOT_URL + 'stores/' + + store_id + "/atg_register"
				axios.get(url, {
                    headers: {
						'Authorization': 'Bearer ' + state.accessToken,
						'Refresh-Token': state.refreshToken				
					},				
                })
				.then( response => {
					
					if (response.data.data.registerExpirationDate) {

						var expireDate = new Date(response.data.data.registerExpirationDate)
					} else {
						expireDate = new Date("2021-01-01")	
					}
					var today = new Date()
					var timeDiff = expireDate.getTime() -  today.getTime(); 
					state.storeRegisterPasswordExpireIn = Math.ceil(timeDiff / (1000 * 3600 * 24))
						
				})
				.catch( (err) => {
					if (process.env.VUE_APP_DEBUG == 'true') console.log(err)
					this.isLoading = false
					if (err.status === 403) {
						alert('Session Expired. Please log in again!')
						state.commit('userLogout')
					}
					var today = new Date()
					var expireDate = new Date("2021-01-01")	
					var timeDiff = expireDate.getTime() -  today.getTime(); 
					state.storeRegisterPasswordExpireIn = Math.ceil(timeDiff / (1000 * 3600 * 24));	
				})				
			}
        },		
		
	},

	actions: {
		setActiveStoreDetails(state, store_id) {
			state.setProgressPercent = 0
			UserStore.commit('setProgressPercent', 20)
			UserStore.commit("setStore", store_id)
			UserStore.commit('setProgressPercent', 30)
			UserStore.commit('fetchStoreSettings', store_id)
			UserStore.commit('setProgressPercent', 40)
			UserStore.commit('fetchStoreState', store_id)
			UserStore.commit('setProgressPercent', 50)
			UserStore.commit('fetchStoreRegisterPasswordExpireIn', store_id)
			UserStore.commit('setProgressPercent', 60)
			UserStore.commit('setActiveStore',store_id)

		},
	}
})

export default UserStore;